.body1StyleSPan {
  color: #ff4b04;
}
.body1StylepFont {
  font-size: 0.28rem;
}
/* @import "../../assets/css/puliceStyle.css"; */
.body1StyleInputStyle {
  border: 0px;
  width: 60%;
}
.bodyStyleDivStyleP {
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}
.bodyStyleDivStyle {
  border-bottom: 1px solid #fbfbfd;
  padding-bottom: 0.3rem;
}
.body1Style {
  margin-top: 0.2rem;
  border-top-left-radius: 0.32rem;
  border-top-right-radius: 0.32rem;
  padding: 0.3rem 0.4rem;
  font-family: PingFangSC-Regular;
  min-height: 91.8vh;
}
.bottom1 {
  margin-top: 2rem;
}
.whole {
  background: #ffffff;
}
.layoutBox {
  height: 100vh;
}
.button {
  border: none !important;
  font-size: 0.24rem;
}
